.modal-edit-imagen{
    position: relative;
    display: inline-block;
    text-align: center;
    .icon-modal{
        position: absolute;
        top: 10%;
        left: 80%;
        cursor: pointer;
    }
}

.video-detail{
  border-radius: 15px;
  box-shadow: 8px 5px 0px rgb(109, 107, 107)
}
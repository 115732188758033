.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl
{
    margin-left: 12.5%;
}

.body-content{
    // justify-content: space-between;
    margin-left: 10%;
    margin-right: 30px;
    display: flex ;
    flex-wrap: wrap;
    align-items: left;
   
}
.card-new{
    justify-content: space-between;
    position: relative;
    width: 300px;
    height: 300px;
    margin-bottom: 60px;
    margin-left: 50px;
}

.card-new .face{
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 10px;
    overflow: hidden;
    transition: .5s;
}

.card-new .front{
    transform: perspective(600px) rotateY(0deg);
    box-shadow: 0 5px 10px #000;
}

.card-new .front img {
    position: absolute;
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

.card-new .front h3 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: #fff;
    background: rgba(0,0,0,.4);
    text-align: center;
    font-size: 1.1rem
}

.card-new .back {
    cursor: pointer;
    transform: perspective(600px) rotateY(180deg);
    background: rgb(3, 35, 54);
    padding: 15px;
    color: #f3f3f3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    box-shadow: 0 5px 10px #000;
   
}

.card-new .back .link{
    border-top: solid 1px #f3f3f3;
    height: 50px;
    font-size: 0.8rem;
}

.card-new .back .link p {
    color: #f3f3f3;
    
}

.card-new .back h3 {
    font-size: 25px;
    margin-top: 10px;
    letter-spacing: 2px;
}

.card-new .back p{
    letter-spacing: 1px;
}
    
.card-new:hover .front{
    transform: perspective(600px) rotateY(180deg);
}

.card-new:hover .back{
    transform: perspective(600px) rotateY(360deg);
}


.cards{
    margin-top: 1%;
    margin-bottom: 2%;
    border-radius: 18px;
    height: 100%;
    width: 100%;
    box-shadow: 0 3px 7px 2px #a8a8ab;
    padding: 10px;
    background-color: white
  }


  @media screen and (max-width: 1100px) {
    .body-content{
        display: flex ;
        flex-wrap: wrap;
        align-items: left;
    }
    .card-new{
        position: relative;
        width: 350px;
        height: 400px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
    }
    .card-new .back h3 {
        font-size: 22px;
        margin-top: 6px;
        letter-spacing: 1.5px;
    }
    
    .card-new .back p{
        letter-spacing: 2px;
        font-size: 18px;
    }
    .card-new .back .link{
        border-top: solid 1px #f3f3f3;
        height: 100px;
        font-size: 0.8rem;
    }
  }

@media screen and (max-width: 900px) {
    .body-content{
        
        display: flex ;
        flex-wrap: wrap;
        align-items: left;
       
    }
    .card-new{
        
        position: relative;
        width: 300px;
        height: 350px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
    }
    .card-new .back h3 {
        font-size: 20px;
        margin-top: 6px;
        letter-spacing: 1.5px;
    }
    
    .card-new .back p{
        letter-spacing: 1.5px;
        font-size: 15px;
    }
    
}

@media screen and (max-width: 768px) {
    .body-content{
        
        display: flex ;
        flex-wrap: wrap;
        align-items: left;
       
    }
    .card-new{
        
        position: relative;
        width: 300px;
        height: 350px;
        margin-bottom: 50px;
        margin-left: auto;
        margin-right: auto;
    }
    .card-new .back h3 {
        font-size: 20px;
        margin-top: 6px;
        letter-spacing: 2px;
    }
    
    .card-new .back p{
        letter-spacing: 2px;
        font-size: 0.9rem
    }
}

@media screen and (max-width: 600px) {
    .body-content{
        flex-wrap: wrap;
    }
    .card-new{
        position: relative;
        width: 240px;
        height: 260px;
        margin-bottom: 25px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .card-new .back h3 {
        font-size: 18px;
        margin-top: 8px;
        letter-spacing: 1.5px;
    }
    
    .card-new .back p{
        letter-spacing: 1px;
        font-size: 12px;
    }
    .card-new .back .link{
        border-top: solid 1px #f3f3f3;
        height: 60px;
        font-size: 0.8rem;
    }
    .cards{
        max-height: 300px;
        overflow: scroll; 
    }
}

@media screen and (max-width: 400px) {
    .body-content{
        display: flex ;
        flex-wrap: wrap;
    }
    .card-new{
        position: relative;
        width: 200px;
        height: 250px;
        margin-bottom: 25px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    .card-new .back h3 {
        font-size: 18px;
        margin-top: 8px;
        letter-spacing: 1.5px;
    }
    
    .card-new .back p{
        letter-spacing: 1px;
        font-size: 10px;
    }

    .cards{
        max-height: 250px;
        overflow: scroll; 
    }
}

@media screen and (max-width: 300px) {
    .body-content{
        display: flex ;
        flex-wrap: wrap;
    }
    .card-new{
        position: relative;
        width: 190px;
        height: 240px;
        margin-bottom: 25px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 200px) {
    .body-content{
        display: flex ;
        flex-wrap: wrap;
    }
    .card-new{
        position: relative;
        width: 190px;
        height: 240px;
        margin-bottom: 25px;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }
    .card-new .back h3 {
        font-size: 18px;
        margin-top: 8px;
        letter-spacing: 1.5px;
    }
    
    .card-new .back p{
        letter-spacing: 1px;
        font-size: 10px;
    }
}



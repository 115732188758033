
.footers{
    margin-top: 1rem;
    padding: 1rem;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height:350px;
    background-size: cover;
    filter:brightness(0.8);
}

.footer-icon{
    display: flex;
}

.redes{
    margin-left: 25%;
}


// @media screen and (max-width: 1100px) {}

@media screen and (max-width: 900px) {
    .footers{
        margin-top: 1rem;
        padding: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height:130px;
        background-size: cover;
        filter:brightness(0.8);
    }
    
    .footer-icon{
        display: flex;
    }
    
    .redes{
        margin-left: 25%;
    }
}

// @media screen and (max-width: 768px) {}

// @media screen and (max-width: 600px) {}

@media screen and (max-width: 400px) {
    .footers{
        margin-top: 1rem;
        padding: 1rem;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height:130px;
        background-size: cover;
        filter:brightness(0.8);
    }
    
    .footer-icon{
        display: flex;
    }
    
    .redes{
        margin-left: 25%;
    }
}

// @media screen and (max-width: 300px) {}

// @media screen and (max-width: 200px) {}
.banner_container_swipper {
  .swiper {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 1000px;
    height: auto;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    border-radius: 19px;
  }
}



.banner_container_detail_swipper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  .container-swipper_one {
    max-width: 48%;
    max-height: 650px;
    min-height: 650px;
  }
  .container-swipper_two{
    max-width: 48%;
    max-height: 650px;
    min-height: 650px;
  
  }
  .swiper {
    width: 90%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    height: auto;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 19px;
  }

  .text-carousel-detail {
    margin-top: 10px;
    font-size: calc(0.2rem + 1vmin + 1vmax);
    color: #fff;
    font-weight: 900;
    line-height: 1.1;
    text-align: center;
    transition: 0.1s;
    filter: drop-shadow(0 0 1px #000) drop-shadow(1px 1px 0 #000) drop-shadow(-1px 1px 0 #000)
      drop-shadow(1px -1px 0 #000) drop-shadow(-1px -1px 0 #000);
  }
}

.banner_container_detail2_swipper {
  display: flex;
  flex-direction: row;
  max-width: 100%;
  .swiper {
    width: 60%;
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    height: auto;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 19px;
  }

  .text-carousel-detail {
    margin-top: 10px;
    font-size: calc(0.2rem + 1vmin + 1vmax);
    color: #fff;
    font-weight: 900;
    line-height: 1.1;
    text-align: center;
    transition: 0.1s;
    filter: drop-shadow(0 0 1px #000) drop-shadow(1px 1px 0 #000) drop-shadow(-1px 1px 0 #000)
      drop-shadow(1px -1px 0 #000) drop-shadow(-1px -1px 0 #000);
  }
}

@media screen and (max-width: 1100px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 780px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
}

@media screen and (max-width: 900px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 680px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }

  .banner_container_detail_swipper {
    display: block;
    .swiper {
      width: 100%;
    }
    .text-carousel-detail {
      font-size: calc(0.2rem + 1vmin + 1vmax);
    }
  }

  .banner_container_detail2_swipper {
    display: block;
    .swiper {
      width: 800px;
      height: 820px;
    }
    .swiper-slide img {
      display: block;
      width: 800px;
      height: 700px;
      border-radius: 19px;
    }
    .text-carousel-detail {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 680px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
  .banner_container_detail2_swipper {
    display: block;
    .swiper {
      width: 750px;
      height: 700px;
    }
    .swiper-slide img {
      display: block;
      width: 750px;
      height: auto;
      max-height: 650px;
      border-radius: 19px;
    }
    .text-carousel-detail {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 600px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 400px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
  .banner_container_detail_swipper {
    display: block;
    .swiper {
      width: 350px;
      height: 340px;
    }
    .text-carousel-detail {
      font-size: calc(0.2rem + 1vmin + 1vmax);
    }
  }

  .banner_container_detail_swipper{
    margin-left: 5px;
    margin-right: 5px;
    .container-swipper_one {
      max-width: 100%;
      max-height: 390px;
      min-height: 390px;
    }
    .container-swipper_two{
      max-width: 100%;
      max-height: 310px;
      min-height: 310px;
    }
  }

  .banner_container_detail2_swipper {
    display: block;
    .swiper {
      width: 350px;
      height: 350px;
    }
    .swiper-slide img {
      display: block;
      width: 350px;
      height: auto;
      max-height: 300px;
      border-radius: 19px;
    }
    .text-carousel-detail {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 350px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
  .banner_container_detail_swipper{
    margin-left: 5px;
    margin-right: 5px;
    .container-swipper_one {
      max-width: 100%;
      max-height: 380px;
      min-height: 370px;
    }
    .container-swipper_two{
      max-width: 100%;
      max-height: 290px;
      min-height: 290px;
    }
  }
  .banner_container_detail_swipper {
    display: block;
    .swiper {
      width: 350px;
      height: 340px;
    }
    .text-carousel-detail {
      font-size: calc(0.2rem + 1vmin + 1vmax);
    }
  }

  .banner_container_detail2_swipper {
    display: block;
    .swiper {
      width: 350px;
      height: 380px;
    }
    .swiper-slide img {
      display: block;
      width: 350px;
      height: auto;
      max-height: 300px;
      border-radius: 19px;
    }
    .text-carousel-detail {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }
}

@media screen and (max-width: 300px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 250px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
}

@media screen and (max-width: 200px) {
  .banner_container_swipper {
    .swiper {
      width: 100%;
      padding-top: 0px;
      padding-bottom: 40px;
    }

    .swiper-slide {
      background-position: center;
      background-size: cover;
      width: 250px;
      height: auto;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      border-radius: 19px;
    }
  }
}

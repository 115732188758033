.carousel-header {
    margin-top: 10px;
}

.content-carousel-detail2 {
    min-width: 55%;

    .carousel-detail {
        width: 100%;
        height: auto;
        position: relative;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        margin-top: 5%;
        .img-carrousel-detail-content{
          width: 200px;
          .img-modal {
            position: relative;
            align-items: center;
            object-fit: scale-down;
            max-width: 100%;
            height: 70vh;
        }
        }
        

        :where(.css-dev-only-do-not-override-mxhywb).ant-image {
            position: relative;
            display: flex;
        }

        .text-carousel-detail {
            margin: auto;
            font-size: calc(0.2rem + 1vmin + 1vmax);
            color: #fff;
            font-weight: 900;
            line-height: 1.1;
            text-align: center;
            transition: .1s;
            filter: drop-shadow(0 0 1px #000) drop-shadow(1px 1px 0 #000) drop-shadow(-1px 1px 0 #000) drop-shadow(1px -1px 0 #000) drop-shadow(-1px -1px 0 #000);
        }
    }
}

.cardtext-detalle-h {
    margin-left: 1%;
    .card-body {
        width: 600px;
        height: 600px;
        min-height: 600px;
        overflow-y: auto;
        .card-text {
            white-space: pre-wrap;
        }
    }
}

.cardtext-detalle-h2 {

    min-width: 40%;
    margin-left: 1%;
    margin-right: 2%;

    .card-body {
        .card-text {
            white-space: pre-wrap;
        }
    }
}

.text-footer {
    margin: auto;
    font-size: calc(0.2rem + 1vmin + 1vmax);
    color: #fff;
    font-weight: 900;
    line-height: 1.1;
    text-align: center;
    transition: .1s;
    filter: drop-shadow(0 0 1px #000) drop-shadow(1px 1px 0 #000) drop-shadow(-1px 1px 0 #000) drop-shadow(1px -1px 0 #000) drop-shadow(-1px -1px 0 #000);
}


@media screen and (max-width: 768px) {
    .cardtext-detalle-h {
        .card-body {
            .card-text {
              font-size: 0.8rem;
              text-align: justify;
              margin: auto;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .cardtext-detalle-h {
        .card-body {
            .card-text {
              font-size: 0.8rem;
              text-align: justify;
              margin: auto;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .cardtext-detalle-h {
        .card-body {
            .card-text {
              font-size: 0.8rem;
              text-align: justify;
              margin: auto;
            }
        }
    }

}

@media screen and (max-width: 300px) {
    .cardtext-detalle-h {
        .card-body {
            .card-text {
              font-size: 0.8rem;
              text-align: justify;
              margin: auto;
            }
        }
    }
}

@media screen and (max-width: 200px) {
    .cardtext-detalle-h {
        .card-body {
            .card-text {
              font-size: 0.8rem;
              text-align: justify;
              margin: auto;
            }
        }
    }
}
.carousel {
    max-width: 100%;
    max-height: 300px;
}

.carousel-detail {
    min-width: 65vh;
    max-width: 65vh;
    max-height: 75vh;
    min-height: 75vh;
}

.image-carrousel-detail {
    min-width: 65vh;
    max-width: 65vh;
    max-height: 75vh;
    min-height: 75vh;
}

.carousel-detail-h {
    min-width: 60%;
    max-width: 60%;
    margin-top: 10px;
}

.image-carrousel-detail-h {}

.content-filter {
    display: flex;
}

.content-filter-button {
    display: flex;
    margin-bottom: 1%;
    margin-top: 1%;
}

.filters {
    margin-right: 1%;
}

.card-proyects-container {
    display: flex;
    flex-wrap: wrap;

    .card-proyects {
        cursor: pointer;
        width: 30%;
        margin: 10px;
        text-align: center;
        height: 600px;

    }

    .definition-card {
        height: 600px;
    }

    .card-body-content {
        margin: 5px;
        width: 150%;
    }

    .card-imagen {
        width: 100%;
        height: 300px;
        border-radius: 5%;
        margin-bottom: 5px;
    }

    .card-direccion {
        position: absolute;
        bottom: 0pt;
        width: 100%;
        font-size: 1.2rem;
    }

    .card-boton {
        display: flex;
    }

    .texto-encima {
        position: absolute;
        top: 12%;
        left: 80%;
    }
}


.description-proyects {
    margin-bottom: 2%;
    margin-left: 1%;
    display: flex;
    max-width: 98%;

    .cardtext-detalle {
        max-width: 600px;
        min-width: 600px;
        max-height: 75vh;
        min-height: 75vh;
        margin-right: 10px;
        overflow-y: auto;
    }

    .card-map {
        min-width: 100%;
        float: right;
        height: 400px
    }

}


.item-card-h {
    align-content: space-between;

    .item-configure {
        width: 20px;
        height: 20px;
        margin-left: 1%;
    }
}

.img-detalle {
    max-width: 480px;
    max-height: 640px
}

.img-detalle-maps {
    max-width: 100%;
    max-height: 384px
}


.maps {
    margin-bottom: 2%;
}

.modal-loguin {
    color: gray;
    font-size: 1.0rem;
    background: rgba(0, 0, 0, 0.8);

    .titulo {
        display: flex;

        .cierra-boton {
            margin-left: auto;
            cursor: pointer;
            color: red;
        }
    }

    .boton-loguin {
        text-align: center;
    }
}

.card-items-detail-h {
    margin-right: 10px;
    margin-left: 1%;
    margin-top: 10px;
    min-width: 98%;
    max-width: 98%;
    max-height: 20%;
    min-height: 20%;
}

.itemsMultiselect::-webkit-scrollbar {
    margin-bottom: 90%;
}

.spinner {
    margin-top: 15%;
    margin-left: 45%;
    height: 40vh;
}

.list-images {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    overflow-y: auto;
    max-width: 500px;
    max-height: 500px;

    .images {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
    }

    &::-webkit-scrollbar {
        width: 8px !important;
    }

    &::-webkit-scrollbar-track {
        background: #c8c4c3ad;
        margin: 20px 0px;
        border-radius: var(--border-radius-m);
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-primary) !important;
        border-radius: 10px;
    }

    h1 {
        color: var(--color-primary);
        font-size: 18px;
    }

    img {
        width: 150px;
        border-radius: 10px;
        box-shadow: var(--box-shadow-light);
    }
}


.contenedor_detalle_uno{
    display: flex;
    flex-direction:row;
    min-width: 100%;
    min-height: 300px;
   
   
    .seccion_uno_detalle_uno{
      background-color: aqua;
      min-width: 50%;
      display: flex;
    }
    .seccion_dos_detalle_uno{
      background-color: red;
      min-width: 50%;
      display: flex;

    }
}

// @media screen and (max-width: 1100px) {}

@media screen and (max-width: 900px) {
    .description-proyects {
        margin-bottom: 2%;
        margin-left: 1%;
        max-width: 98%;
        display: block;
          
    }
    .carousel-detail {
        min-width: 50vh;
        max-width: 50vh;
        max-height: 43vh;
        min-height: 43vh;
    }
}

// @media screen and (max-width: 768px) {}

// @media screen and (max-width: 600px) {}

@media screen and (max-width: 400px) {
  .description-proyects {
    margin-top: 20px;
    margin-bottom: 2%;
    margin-left: 1%;
    max-width: 98%;
    display: block;
      
}
}

@media screen and (max-width: 300px) {
  .description-proyects {
    margin-top: 10px;
    margin-bottom: 2%;
    margin-left: 1%;
    max-width: 98%;
    display: block;
      
}
}

// @media screen and (max-width: 200px) {}
.body {
    width: 100%;
    height: 150px;
    background-color: white;
}

.navbar {
    padding-left: 0 !important;
    padding-right: 0 !important;
    width: 100% !important;
    height: 150px;
    background-size: contain;
    background-repeat: no-repeat;
}

.icons-navbar {
    display: flex, ;
    margin-left: 30%;

    .size-icons {
        width: 180px;
    }

    .format-icons{
        font-size: 40px;
        color: #3487B9;
    }
}


// crear responsive de la clase icons-navbar para moviles y tablets

@media screen and (max-width: 1100px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex ;
        margin-left: 35%;
        margin-top: -40px;

        .size-icons {
            width: 140px;
        }
        .format-icons{
            font-size: 50px;
            color: #3487B9;
        }
    }
}


@media screen and (max-width: 900px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 30%;
        margin-top: 10px;

        .size-icons {
            width: 140px;
        }
        .format-icons{
            font-size: 50px;
            color: #3487B9;
        }
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 30%;
        margin-top: 10px;

        .size-icons {
            width: 140px;
        }
        .format-icons{
            font-size: 50px;
            color: #3487B9;
        }
    }
}

@media screen and (max-width: 600px) {
    .body {
        width: 100%;
        height: 100px;
        background-color: white;
    }
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 25%;
        margin-top: -0px;

        .size-icons {
            width: 120px;
        }
        .format-icons{
            font-size: 30px;
            color: #3487B9;
        }
    }
}

@media screen and (max-width: 400px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 0%;
        margin-top: 35px;

        .size-icons {
            width: 120px;
        }
        .format-icons{
            font-size: 35px;
            color: #3487B9;
        }
    }
    
}

@media screen and (max-width: 300px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 0%;
        margin-top: 30px;

        .size-icons {
            width: 90px;
        }
        .format-icons{
            font-size: 20px;
            color: #3487B9;
        }
    }
}

@media screen and (max-width: 200px) {
    .navbar {
        padding-left: 0 !important;
        padding-right: 0 !important;
        width: 100% !important;
        height: auto;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .icons-navbar {
        display: flex, ;
        margin-left: 0%;
        margin-top: 30px;

        .size-icons {
            width: 120px;
        }
        .format-icons{
            font-size: 20px;
            color: #3487B9;
        }
    }
}


// @media screen and (max-width: 1100px) {}

// @media screen and (max-width: 900px) {}

// @media screen and (max-width: 768px) {}

// @media screen and (max-width: 600px) {}

// @media screen and (max-width: 400px) {}

// @media screen and (max-width: 300px) {}

// @media screen and (max-width: 200px) {}
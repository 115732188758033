.conten-coment{
    margin-bottom: 6%;
}
.botonForm{
    float: right;
}

.alert{
    width: 100%;
    height: 369px;
    .spinner{
        margin-left: 45%;
        margin-top: 5%;
    }
    .spinner-2{
        margin-left: 35%;
        margin-top: 20%;
    }
}


// @media screen and (max-width: 768px) {}

// @media screen and (max-width: 600px) {}

// @media screen and (max-width: 400px) {}

// @media screen and (max-width: 300px) {}

// @media screen and (max-width: 200px) {}